.form {
  width: 100%;
  max-width: 100%;

  small {
    display: inline-block;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
}

.form--standard {
  max-width: var(--small-text-width);
  margin-inline: auto;
}

.form--comments {
  max-width: var(--small-text-width);
}

.form__error-text {
  display: none;

  form.form--error & {
    display: block;
  }
}

.form__success-text {
  display: none;
  form.form--success & {
    display: block;
  }
}

.form__disabled-text {
}

.form fieldset {
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  legend {
    padding-inline: 0.5rem;
    margin-inline: -0.5rem;
    margin-bottom: 0.5em;
  }
}

.form__item,
.container--cmp {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;

  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
  textarea,
  select {
    border-radius: 0.25rem;
    border: var(--space-space-0, 1px) solid rgba(0, 0, 0, 0.5);
    width: 100%;
    background: var(--white);
    padding: 6px 21px 4px 21px;
  }

  select {
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;

    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 60%;
    background-size: 0.65rem auto;
  }

  input[type="checkbox"],
  input[type="radio"] {
    background: var(--white);
  }

  textarea {
    resize: vertical;
    display: block;
    min-height: 2em;
  }

  label {
    display: block;
    margin-bottom: 0.25rem;
  }
}

/* /* .form__item--textarea,
.widget--textarea {
} */

/* .form__item--emailinput,
.widget--emailinput {
} */

/* .form__item--textinput,
.widget--textinput {
} */

.form__item--radioinput,
.form__item--checkboxinput,
.widget--checkboxinput,
.widget--radioinput {
  display: flex;
  gap: 1rem;

  input {
    flex: 0 0 1rem;
  }

  label {
    flex: 1 1 auto;
  }
}

.form__item--radioinput,
.widget--radioinput {
  margin-bottom: 0.25rem;

  .signup-form__regions & {
    margin-bottom: 0.75rem;
  }
}

.widget--radioselect .form__item-inner {
  label {
    display: flex;
    gap: 1rem;

    input {
      flex: 0 0 1rem;
    }

    &::after {
      content: "";
      display: none;
    }
  }
}

.form__item--dateinput,
.widget--emptylabelselectdatewidget {
  .form__item-inner {
    display: flex;
    gap: 0.5rem;
  }
}

.form__item--submit {
}

.required label::after,
label.required::after {
  content: " *";
}

.errorlist {
  scroll-margin-top: 50vh;
}

ul.errorlist {
  display: block;
  margin-top: 0.25rem;
  padding: 0 0.25rem;
  background-color: var(--red);
  color: var(--white);
  border-radius: 0.25rem;
}

.loading {
  display: flex;
  gap: 1rem;
  align-items: center;

  &::after {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    border: 3px solid transparent;
    border-top: 3px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
